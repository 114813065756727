import { events } from "../shared/easyfy.decorators";
import { smoothScroll } from "../shared/easyfy.utils";

export default class ScrollTarget {
  constructor() { }

  @events({ target: "scroll-target", type: "click" })
  onScrollToTarget(this: Element) {
    let target = this.attributes["data-target"]?.value;
    smoothScroll(`[data-container="${target}"]`);

    return false;
  }
}