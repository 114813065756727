import { invoke } from '../../shared/easyfy.decorators';
import easyfyScrollTarget from '../../components/scroll-target';
import 'lazysizes';

// ###################################################################
// Denna använder _Layout_LocationSelector.cshtml
// ###################################################################
@invoke
class Location {
  constructor() {
    new easyfyScrollTarget();
  }

  onInit() {}
}
